import React, { useState } from 'react';
import './portfolio.scss';  // Ensure your CSS file is correctly linked

const Portfolio = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  // Example video data
  const videos = [
    { id: '1', title: 'Diseases: Cancer', videoId: 'dWhQ6PQMrnM' },
    { id: '2', title: 'Digital Therapeutics', videoId: 'VePOmff_Q_0' },
    { id: '3', title: 'Data Integrity in Pharma Research Labs', videoId: 'ZXdfhDRPSJM' },
    { id: '4', title: 'Vaccines', videoId: 'N1pgVPzenL0' },
    { id: '5', title: 'Symptom Syndrome Disease Disorder', videoId: '6hpiS-ZjCtU' },
    { id: '6', title: 'US Pharma Market', videoId: 'mEUBEy-jDQc' },
  ];

  const openModal = (videoId) => {
    setCurrentVideo(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo('');
  };

  return (
    <div id="portfolio">
      <h1><center>Sample Stories by APC</center></h1>
      <div className="video-grid">
        {videos.map((video) => (
          <div key={video.id} className="video-item" onClick={() => openModal(video.videoId)}>
            <img src={`https://img.youtube.com/vi/${video.videoId}/0.jpg`} alt={video.title} />
            <p>{video.title}</p>
          </div>
        ))}
      </div>
      {modalIsOpen && (
  <div>
    <div className="modal-overlay" onClick={closeModal}></div>
    <div className="video-modal" onClick={(e) => e.stopPropagation()}>
      <button className="close-button" onClick={closeModal}>&times;</button>
      <iframe
        src={currentVideo}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
        width="100%"
        height="500px"  /* Adjust height as needed */
      ></iframe>
    </div>
  </div>
)}
    </div>
  );
};

export default Portfolio;
