import React from 'react';
import './datavisual.scss'; // Ensure this is correctly pointing to your SCSS file

const DataVisualization = () => {
  return (
    <div className="visual-section">
      <div className="visual-content">
        <h1>A picture is worth a thousand words. Or a million data points.</h1>
        <p>APC uses powerful data visualization tools to separate the wheat from the chaff.</p>
      </div>
    </div>
  );
};

export default DataVisualization;
