import React from 'react';
import './vr.scss';

const VRSection = () => {
  return (
    <div className="vr-section">
      <div className="vr-video">
        <iframe
          src="https://www.youtube.com/embed/7FnMWr1XN48"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="A Virtual walk thru a pharma quality control lab"
        ></iframe>
      </div>
      <div className="vr-content">
        <h1>VR. without the hype.</h1>
        <p><center>apc couples realistic VR environments (like this pharma quality control lab) with intelligent scripts for hands-on training virtually.</center></p>
      </div>
    </div>
  );
};

export default VRSection;
