import React from 'react';
import './about.scss';

// Assuming the other part of your Photos component is already set up
const Photos = () => {
  return (
    <div className="photos-wrapper">
      {/* Existing content here */}
      <div className="additional-photos">
        <div className="photo-item">
          <img src={require('../../assets/photos/7.jpeg')} alt="Learning Activity 7" />
        </div>
        <div className="photo-item">
          <img src={require('../../assets/photos/8.jpeg')} alt="Learning Activity 8" />
        </div>
      </div>
    </div>
  );
};

export default Photos;
