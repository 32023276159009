import React from 'react';
import './photos.scss';
import img1 from '../../assets/photos/1.jpg';
import img2 from '../../assets/photos/2.jpeg';
import img3 from '../../assets/photos/3.jpeg';
import img4 from '../../assets/photos/4.jpeg';
import img5 from '../../assets/photos/5.jpeg';
import img6 from '../../assets/photos/6.jpeg';

const photos = [
  { id: 1, src: img1 },
  { id: 2, src: img2 },
  { id: 3, src: img3 },
  { id: 4, src: img4 },
  { id: 5, src: img5 },
  { id: 6, src: img6 }
];

const Photos = () => {
  return (
    <div className="photos-wrapper">
      <h1 className="title">Learning for All, All for Learning</h1>
      <div className="content">
        <div className="description">
          <p>APC believes in learning for everyone. Since 2011, we have partnered with Swabhimann Trust to bring digitally enhanced learning to the Ejipura slums in our hometown of Bengaluru.</p>
        </div>
        <div className="photos-grid">
          {photos.map(photo => (
            <div key={photo.id} className="photo-item">
              <img src={photo.src} alt={`Learning Activity ${photo.id}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Photos;
