import React from 'react';
import videoSrc from '../../assets/reddy/video.mp4';  // Make sure this path is correct based on your project structure
import './reddy.scss'; // Ensure this points to the correct SCSS file relative to this JS file
  

const Reddy = () => {
  return (
    <div className="reddy-section">
      
      <div className="text-container">
        <h2>See how APC helped Dr. Reddy's define what comes next.</h2>
      </div>
      <div className="video-container">
        <video controls>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      
      
    </div>
  );
};



export default Reddy;
