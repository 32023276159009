import React from "react";
// SCSS
import "./hero.scss";
// Assets
import HeroImage from '../../assets/hero/hero-image.jpeg';
// Components
import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero" style={{ backgroundImage: `url(${HeroImage})` }}>
    <div className="hero-info">
      <h1 className="weight800 font60">Complex concepts. Explained better.</h1>
      <p className="font12">
        apc combines deep technical expertise, clean scripts, and state-of-the-art software to make
        insightful VR and eLearning modules.
      </p>
      <Button label="SEE SOME OF OUR WORK"  target={"portfolio"} />
    </div>
  </div>
);

export default hero;
