import React from "react";
import "./stories-section.scss";  // Ensure the SCSS is properly imported

const StoriesSection = () => (
  <div className="stories-section" id="stories">
    <div className="content">
      <div className="text-section">
        <h1>"We need stories to understand ourselves."</h1>
        <p className="author">- Salman Rushdie</p>
        <p>Learning content is better retained if it is weaved into stories.</p>
      </div>
      <div className="video-section">
        {/* <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/watch?v=RPDOioWeByo?autoplay=1&mute=1"  // Replace YOUR_VIDEO_ID with the actual ID
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe> */}
        <iframe
          src="https://www.youtube.com/embed/RPDOioWeByo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="salman"
        ></iframe>
        
      </div>
    </div>
  </div>
);

export default StoriesSection;
