import React from 'react';
import './aboutus.scss';
import ashifImage from '../../assets/aboutus/ashif.png'; // Import Ashif's image
import arjunImage from '../../assets/aboutus/arjun.jpeg'; // Import Arjun's image
const linkedInIconUrl = 'https://cdn-icons-png.flaticon.com/512/174/174857.png'; 

const AboutUs = () => {
  return (
    <div className="aboutUs-section">
      <h2>APC is made up of people passionate about technology + storytelling</h2>
      <div className="profile-container">
        <div className="profile">
          <img src={ashifImage} alt="Ashif" />
          <p>Ashif Panakkat is an engineer and serial entrepreneur who has provided engineering and learning solutions to Apple, McDonalds, Starbucks, and Dr. Reddy's</p>
          <a href="https://www.linkedin.com/in/ashif-panakkat">
          <img src={linkedInIconUrl} alt="LinkedIn" className="linkedin-icon"/>
          </a>
        </div>
        <div className="profile">
          <img src={arjunImage} alt="Arjun" />
          <p>Arjun Rao is a filmmaker, documentarian, and storyteller who has worked on award-winning pieces, including for Discovery, PBS, Smithsonian, and the History Channel.</p>
          <a href="https://www.linkedin.com/in/arjun-rao">
          <img src={linkedInIconUrl} alt="LinkedIn" className="linkedin-icon"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
