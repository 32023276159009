import React from 'react';
import { Link } from "react-scroll";
// SCSS
import './navbar.scss';
// Assets
import LogoImg from '../../assets/navbar/logo.png'; // Updated to use .png logo
import MobileMenuIcon from '../../assets/navbar/mobile-menu.svg';

const DesktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? "extraLargeNavbar" : ""}`}>
    <div className="wrapper flex-s-between">
      <div>
        {/* Link to hero section with smooth scrolling */}
        <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
          <img src={LogoImg} alt="logo" className="pointer" width="240px" />
        </Link>
      </div>
      <div className="mobile__menu" onClick={props.mobileMenuOpen}>
        <img src={MobileMenuIcon} alt="menu" />
      </div>
      <div className="desktop__menu">
        <ul className="flex-s-between">
          <li>
            <Link activeClass="active-link" to="stories-section" spy={true} smooth={true} offset={-70} duration={500}>
              STORIES
            </Link>
          </li>
          <li>
            <Link activeClass="active-link" to="aboutUs-section" spy={true} smooth={true} offset={-70} duration={500}>
              ABOUT
            </Link>
          </li>
          <li>
            <Link activeClass="active-link" to="photos-wrapper" spy={true} smooth={true} offset={-70} duration={500}>
              LEARNING FOR ALL
            </Link>
          </li>
          <li>
            <Link activeClass="active-link" to="footer" spy={true} smooth={true} offset={-70} duration={500}>
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default DesktopNav;
