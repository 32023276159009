import React from "react";
import "./footer.scss";

import Logo from '../../assets/footer/logo.png';

const Footer = () => (
  <div className="footer">
    <div className="wrapper">
      <div className="footer-content">
        <img src={Logo} alt="APC Learn Logo" className="footer-logo" />
        <div className="footer-contact">
          <span className="contact-number">+91 9972294650</span>
          <span className="contact-email">hi@apclearn.com</span>
        </div>
      </div>
      <div className="footer-ownership">
        wholly owned by M/S ashif panakkat consultant, kozhikode, kerala, india. (c) all rights reserved
      </div>
    </div>
  </div>
);

export default Footer;
