import React from "react";
import "./indian-story.scss";  // Ensure the corresponding SCSS file is included

const IndianStory = () => (
  <div className="indian-story" id="indian-story">
    <h1 className="heading">Indian stories. in Indian languages.</h1> {/* Centered heading */}
    <div className="content">
      <div className="text-section">
        <p>
          apc believes in the power of telling local stories in local languages. Our eLearning modules can be enjoyed in Hindi, Tamil, Kannada, and Malayalam, along with English.
        </p>
      </div>
      <div className="video-section">
        
        <iframe
          src="https://www.youtube.com/embed/ITeoLU-Uvks"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="salman"
        ></iframe>
      </div>
    </div>
  </div>
);

export default IndianStory;
